<div class="removeAccount">
    <!-- <div class="image">
     <img class="img" src="/assets/images/phone.svg" alt="">
    </div> -->
    <div class="contentText">
        <h3>Demande de suppression de mon compte  agbofy </h3> <br>
        <p>Une fois la demande effectuée, votre compte sera desactivé pendant 90 jours.
            Une fois les 90 jours passés, la supresssion définitive du compte sera effective.</p> <br>

            <h5>Vous pourrez annuler la demande a tout moment avant l'echeance de 90 jours</h5> <br>
        <button class="btns" type="submit">Supprimer le compte </button>
        <hr>
        <p>Si vous ne parvenez pas à supprimer le compte utilisateur agbofy , veuillez <span ><a class="contact" href="#">nous contactez</a></span></p>
    </div>
</div>

