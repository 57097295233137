import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { routeMap } from 'src/app/core/routeMap';

@Component({
  selector: 'app-haeder',
  templateUrl: './haeder.component.html',
  styleUrls: ['./haeder.component.less']
})
export class HaederComponent implements OnInit {
  
  ismobileOpen:boolean = false;

  @ViewChild('hamburger', { static: false }) toggler: ElementRef| undefined;
  @ViewChild('navLinksContainer', { static: false }) navLinksContainer: ElementRef |undefined;

  ngOnInit(): void {
    
  }
  constructor( private router:Router, private renderer:Renderer2){}

  ngAfterViewInit() {
//     if (this.toggler) {
//       this.renderer.listen(this.toggler.nativeElement, 'click', () => {
//         this.toggleNav();
//      });
  //  }
}
  toggleNav() {
      
    if (this.toggler && this.navLinksContainer) {
      // Animation du bouton 
    
      this.renderer.addClass(this.toggler.nativeElement, 'open');

      const ariaToggle =
        this.toggler.nativeElement.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
        this.ismobileOpen = !this.ismobileOpen;
        console.log(  this.toggler.nativeElement.getAttribute('aria-expanded'));
        
        if(this.ismobileOpen){
          this.renderer.setAttribute(this.toggler.nativeElement, 'aria-expanded', ariaToggle);

            // Slide de la navigation
            this.renderer.addClass(this.navLinksContainer.nativeElement, 'open');
        }else{
          this.removeSideBar();
        }
      
    }



  }

removeSideBar(){
  const ariaToggle =
        this.toggler?.nativeElement.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
  this.renderer.removeAttribute(this.toggler?.nativeElement, 'aria-expanded');
  // Slide de la navigation
  this.renderer.removeClass(this.navLinksContainer?.nativeElement, 'open');
  this.renderer.removeClass(this.toggler?.nativeElement, 'open');
  this.toggler?.nativeElement.setAttribute('aria-expanded', false)
}


  goToTarif(){
    this.router.navigate([routeMap.tarificationRoute.tarificaton]);
    this.removeSideBar();
  }
  goAide(){
    this.router.navigate([routeMap.aideRoute.aide]);
    this.removeSideBar();
  }

  goContact(){
    this.router.navigate([routeMap.contact]);
    this.removeSideBar();
  }
}
