import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { routeMap } from 'src/app/core/routeMap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent {
  constructor(private _router: Router) {}

  goCGU() {
    this._router.navigate([routeMap.cgu]);
  }
  goPrivacy() {
    this._router.navigate([routeMap.privacy]);
  }

  activeAccount(){
    this._router.navigate([routeMap.reactivation]);
  }
  suppAccount(){
    this._router.navigate([routeMap.removeAccount]);
  }
}
