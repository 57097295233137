import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reactivation',
  templateUrl: './reactivation.component.html',
  styleUrls: ['./reactivation.component.less']
})
export class ReactivationComponent {

  currentStep: number = 0;
  smsForm: FormGroup | undefined;
  isLoading: boolean = false;
  requestId: string | null = null;
  requestMessage: string = '';


  STEPS: Array<any> = [
    {
      key: 0,
      name: 'TYPE_COMPTE',
    },

    // {
    //   key: 3,
    //   name: 'PRODUCT_SPECIFICITY',
    // },
    {
      key: 1,
      name: 'TYPE_COMPTE',
    },
    {
      key: 2,
      name: 'QUESTION',
    },
    {
      key: 3,
      name: 'INFORMATION',
    },
    {
      key: 4,
      name: 'WARNING',
    },
    {
      key: 5,
      name: 'DIALOGUE',
    },
  ];

  typeCompte = '';
  question = '';
  information = '';
  warning = '';
  dialogue = '';

  goNextStep(event: any) {
    console.log(event);
    if (event.formKey === 0) {
      this.typeCompte = event.name;
    }
    if (event.formKey === 1) {
      this.question = event.name;
    }
    if (event.formKey === 2) {
      this.information = event.name;
    }
    if (event.formKey === 3) {
      this.warning = event.name;
    }
    if (event.formKey === 4) {
      this.dialogue = event.name;
    }
    if (event.formKey === 6) {
      //Gerer l'image du produit

      this.onSubmit();
      return;
    }
    this.currentStep += 1;
  }
  onSubmit() {
    if (this.currentStep < 3) {
      this.currentStep++;
      return;
    }
  }

  goPrevious() {
    if (this.currentStep >= 2) {
      this.currentStep--;
    }
  }

  onSendDeletionCode(){

  }
}
