import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarification',
  templateUrl: './tarification.component.html',
  styleUrls: ['./tarification.component.less']
})
export class TarificationComponent implements OnInit {

  ngOnInit(): void {
    
  }

  leftClick(){
    
  }

  rightClick(){

  }

}
