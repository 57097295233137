
<app-spinners></app-spinners>

<div class="removeAccount">
   
    <ng-container *ngIf="currentStep === 0">
        <div class="contentText">
            <h3>Réactivation de compte agbofy </h3> <br>
            <p>La suppression de compte  Agbofy se fait en 90 jours, 
                vous pouvez demander une restauration si votre demande de suppression date de moins de 90 jours.</p> <br>
    
                <h5>Si vous souhaitez restaurer votre compte, veuillez cliquez sur le bouton ci-dessous </h5> <br> <br>
            <button (click)="onSubmit()" class="btns" type="submit">Suivant </button>
            <hr>
            <p>Si vous ne parvenez pas à réactiver le compte utilisateurs agbofy , veuillez <span class="contact">nous contactez</span></p>
        </div>
    </ng-container>

    <ng-container *ngIf="currentStep === 1">
        <div class="contentText">

            <div class="icons">
                <img src="/assets/images/utilisateur.png" alt="">
            </div>

        <h3>Réactivation  de  votre compte </h3>
        <form  class="form">
            <div class="groupForm">
            <label for="">Choix de l'application utilisé</label> <br>
            <select name="" id="">
                <!-- <option value="">Choisir le type de compte </option> -->
                <option value="">J'utilise l'application client</option>
                <option value="dog">J'utilise l'application forunisseur </option>
                <option value="cat">J'utilise l'application client</option>
            </select>
            </div>

            <div class="d-flex">
                <div class="groupForm">
                    <label for="">Email</label> <br>
                   <input type="email" placeholder="Entrer votre email">
                </div>
                <p> OU</p>
                <div class="groupForm">
                    <label for="">Numero de téléphone</label> <br>
                   <input type="number" placeholder="Entrer le numéro ">
                </div>
            </div>
            <br>
            <button (click)="onSubmit()" class="btns" type="submit">Suivant</button>
        </form>
         </div>
    </ng-container>

    <ng-container  *ngIf="currentStep === 2">
        <div class="contentText">

            <div class="icons">
                <img src="/assets/images/verifier.png" alt="">
            </div>

            <h3>Comptes Restaurés  avec succès  </h3> <br>
            
                <p>Votre demande de récupération de compte a été effectué. Vous pouvez maintenant vous reconnecter.</p> <br>
           
            <hr>
            <p>Si vous voulez encore plus d'information  , veuillez <span ><a class="contact" href="#">nous contactez</a></span></p>
        </div>
    </ng-container>

    <ng-container  *ngIf="currentStep === 3">
        <div class="contentText">

            <div class="icons" style="background-color: red;">
                <img src="/assets/images/alerte.png" alt="">
            </div>

            <h3>Erreur de récupération  </h3> <br>
            
                <p>a demande de suppression de votre compte ait dépassé la période de 90 jours, ce qui rend impossible la récupération de votre compte.</p> <br>
           
            <hr>
            <p>Si vous voulez encore plus d'information  , veuillez <span ><a class="contact" href="#">nous contactez</a></span></p>
        </div>
    </ng-container>

</div>


