<div class="removeAccount">
    <div class="image">
     <img class="img" src="/assets/images/phone.svg" alt="">
    </div>
    <div class="contentText">
         <img  src="/assets/images/delete.png" alt="">

            <h5>Votre compte à été supprimer ave succès </h5> <br>
        <hr> <br>
        <p>Si vous aimez ce produit veuillez le partager avec d’autres </p>
    </div>
</div>


