export const routeMap = {
  homeRoute: {
    home: '/home',
  },
  cgu: '/cgu',
  privacy: '/privacy',

    tarificationRoute:{
        tarificaton:'/tarification'
    },
    aideRoute:{
        aide:'/aide'
    },

    removeAccount:'./remove-account',
    reactivation:'/reactivation',
    delete:'/delete',
    contact:'/contact'

}
