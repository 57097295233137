import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { HomeComponent } from './home/home.component';
import { TarificationComponent } from './tarification/tarification.component';
import { RemoveAcountComponent } from './remove-acount/remove-acount.component';
import { ReactivationComponent } from './reactivation/reactivation.component';
import { SuccessComponent } from './components/success/success.component';
import { SuppComponent } from './components/supp/supp.component';
import { AideComponent } from './aide/aide.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CguComponent } from './cgu/cgu.component';
import { ContactComponent } from './contact/contact.component';
import { UiModule } from "../../shared/ui/ui.module";


@NgModule({
    declarations: [
        HomeComponent,
        TarificationComponent,
        RemoveAcountComponent,
        ReactivationComponent,
        PrivacyComponent,
        CguComponent,
        SuccessComponent,
        SuppComponent,
        AideComponent,
        ContactComponent
    ],
    imports: [
        CommonModule,
        PagesRoutingModule,
        UiModule
    ]
})
export class PagesModule { }
