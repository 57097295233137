import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TarificationComponent } from './tarification/tarification.component';
import { RemoveAcountComponent } from './remove-acount/remove-acount.component';
import { ReactivationComponent } from './reactivation/reactivation.component';
import { DeleteComponent } from './delete/delete.component';
import { SuccessComponent } from './components/success/success.component';
import { SuppComponent } from './components/supp/supp.component';
import { AideComponent } from './aide/aide.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CguComponent } from './cgu/cgu.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'aide',
    component: AideComponent,
  },
  {
    path: 'tarification',
    component: TarificationComponent,
  },
  {
    path: 'remove-account',
    component: RemoveAcountComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'reactivation',
    component: ReactivationComponent,
  },
  {
    path: 'delete',
    component: DeleteComponent,
  },
  {
    path: 'success',
    component: SuccessComponent,
  },
  {
    path: 'revoke',
    component: SuppComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'cgu',
    component: CguComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
