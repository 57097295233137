
<app-spinners></app-spinners>
<div class="containerContact">


    <div class="app-contact">
        <h3>Besoin d'aide ? Contactez-nous ! </h3>
        <h5>Nous sommes là pour vous aider ! Si vous avez des questions, des préoccupations ou si vous avez besoin d'assistance, n'hésitez pas à nous contacter.</h5>
    </div>

    <div class="contactForm">

        <h1>Contacts</h1>
        <form >
            <div class="groupForm">
                <input type="text" placeholder="Votre nom">
                <input type="email" placeholder="Votre email">
            </div>
            <div class="groupForm">
                <input type="number" placeholder="Votre contact">
               
            </div>
            <div class="groupForm">
                <textarea name="" id="" cols="30" rows="10" placeholder="Votre message">
                    
                </textarea>
            </div>

            <button type="button" class="commencer">Soumettre</button>
            
        </form>
    </div>

</div>
