<app-spinners></app-spinners>
<div class="container-tarification">
  <div class="app-tarification">
    <h3>POLITIQUE DE CONFIDENTIALITÉ DES APPLICATIONS AGBOFY</h3>
  </div>

  <div class="cgu-content">
   
    <p style="color: gray; font-weight: bold;">Dernière mise à jour Mars 2024  </p>

    <p>Bienvenue !</p>
    <p>
      Nous avons adopté cette politique de confidentialité, qui détermine la manière dont nous traitons les informations collectées , qui fournit également les raisons pour lesquelles nous devons collecter certaines données personnelles vous concernant. Par conséquent, vous devez lire cette politique de confidentialité avant d'utiliser les applications d’ AGBOFY . Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité et la sécurité. Les informations personnelles que nous collectons

    </p> <br>

    <p>
      <span class="bold">1. QUELLES INFORMATIONS COLLECTONS-NOUS ?</span> 
    </p>
    <p>Informations personnelles que vous nous divulguez
      En bref : Nous collectons les informations personnelles que vous nous fournissez. <br>
      Nous collectons les informations personnelles que vous nous fournissez volontairement lorsque vous vous inscrivez sur nos différentes applications, exprimant votre intérêt à obtenir des informations sur nous ou sur nos produits et services, lorsque vous participez à des activités sur les services, ou autrement lorsque vous nous contactez.
    </p>
    <p>
    <span style="font-weight: bold;">  Information sensible</span>. Nous ne traitons pas d'informations sensibles. Toutes les informations personnelles que vous nous fournissez doivent être véridiques, complètes et exactes, et vous devez nous informer de toute modification apportée à ces informations personnelles.
    </p>
    <p>
      Informations collectées automatiquement <br>
        En bref : Certaines informations, telles que votre adresse IP (Internet Protocol) et/ou les caractéristiques de votre navigateur et de votre appareil, sont collectées automatiquement lorsque vous utilisez nos applications. <br>
        Nous collectons automatiquement certaines informations lorsque vous visitez, utilisez ou naviguez sur les Services. Ces informations ne révèlent pas votre identité spécifique (comme votre nom ou vos coordonnées) mais peuvent inclure des informations sur l'appareil et son utilisation, telles que votre adresse IP. <br>

        AGBOFY intègre 3 applications mobile en son sein


    </p>
    <p>
      <span class="bold">A- APPLICATION LIVREUR</span> 
    </p>
    <p>Nous collectons les informations suivantes pour optimiser nos services de livraison :
      - Emplacement en temps réel : Utilisé pour suivre les livraisons en direct et améliorer l'efficacité des itinéraires. <br>
      - Détails de chaque livraison effectuée : Nécessaires pour évaluer les performances du livreur et garantir la qualité du service. <br>
      - Évaluations des clients : Les retours des clients aident à améliorer continuellement la prestation des livreurs. <br>
      - Nous collectons pour l’inscription du livreur le (le numéro de téléphone et son mot de passe)

    </p>

    <p>
      <span class="bold">B- APPLICATION MARCHAND </span>
    </p>

    <p>Les informations collectées auprès des marchands incluent des détails professionnels et de contact     nécessaires aux transactions : <br>
      - Informations professionnelles : Ces détails sont utilisés pour établir et maintenir une relation commerciale fructueuse. <br>
      - Coordonnées : Les informations de contact sont cruciales pour faciliter la communication liée aux services fournis. <br>
      - Nous collectons à l’inscription <br>
      -Téléphone <br>
     - Nom du compte  <br>
     - Email <br>
      -Mot de passe <br>
      
    </p>

    
    <p>
      <span class="bold">C- APPLICATION CLIENT </span>
    </p>

    <p>Les informations collectées auprès des clients incluent des détails de contact et de facturation nécessaires aux transactions : <br>
      - Coordonnées : Les informations de contact sont utilisées pour faciliter la communication liée aux services utilisés. <br>
      - Détails de facturation : Ces informations sont nécessaires pour les transactions et les paiements. <br>
      - Détails sur votre adresse pour confirmer une commande telques : la ville ,
       le contact et le quartier  <br>
      - Le client peut ne pas donner  d’information pour utiliser l’application client . <br>
      - Mais dans le souci  de faciliter vos prochains achats et le suivi de vos commandes vous pouvez créer un compte dont nous récupérons les informations suivantes : le nom, le téléphone et le mot de passe .
      
      <p>
        <span class="bold">2. UTILISATION DES DONNÉES </span> 
      </p>
    
      <div>
        <p>Nous vous informons que les données collectées sont utilisées dans le seul but de fournir, maintenir, protéger et améliorer nos applications. Ces données peuvent également être utilisées à des fins statistiques internes pour analyser et comprendre l'utilisation de nos services, ainsi que pour personnaliser le contenu et les annonces que vous voyez. <br>
          Nous nous engageons à ne pas utiliser vos données à des fins autres que celles décrites dans cette politique de confidentialité sans votre consentement préalable.
          
          </p>
    
          <p>Voici quelques exemples d'utilisation des données :
            - <span style="font-weight: bold;"> Dans l’application livreur</span> les données collectées sont strictement utilisées aux fins suivantes : <br>
            - Amélioration et gestion de nos services de livraison : Les informations sont analysées de manière agrégée pour identifier des tendances et des domaines d'amélioration. <br>
            - Aucune information personnelle du livreur ne sera partagée sans son consentement explicite : Le respect de la vie privée du livreur est notre priorité, et aucune donnée personnelle ne sera divulguée sans autorisation. <br>
            - <span style="font-weight: bold;"></span> Les données collectées sont utilisées exclusivement pour la gestion des transactions et la communication liée aux services fournis : <br>
            - Facturation et paiements : Les informations de facturation sont utilisées uniquement à des fins de transactions et de paiements. <br>
            - Aucune information personnelle du fournisseur n'est partagée sans son consentement explicite : Nous respectons la confidentialité des fournisseurs et ne partageons aucune donnée personnelle sans autorisation. <br>
            - <span style="font-weight: bold;">Dans l’application client</span> Les informations collectées auprès des clients incluent des détails de contact et de facturation nécessaires aux transactions : <br>
            - Coordonnées : Les informations de contact sont utilisées pour faciliter la communication liée aux services utilisés. <br>
            - Détails de facturation : Ces informations sont nécessaires pour les transactions et les paiements. <br>
            
          </p>
        
      <p>
        <span class="bold">3. SÉCURITÉ DES DONNÉES </span>
      </p>

      <p>
        En gros, la sécurité de vos données personnelles est une priorité absolue pour nous. Nous prenons des mesures appropriées pour protéger vos données contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction non autorisée. <br>
        Nous mettons en œuvre des mesures de sécurité techniques, administratives et physiques pour protéger vos données personnelles. Ces mesures comprennent, sans s'y limiter, le cryptage des données, l'accès restreint aux données par du personnel autorisé uniquement. <br>
        Nous réalisons régulièrement des évaluations des risques pour identifier et atténuer les menaces potentielles pour la sécurité de vos données personnelles. En cas d'incident de sécurité affectant vos données personnelles, nous nous engageons à notifier les autorités compétentes et vous informer de l'incident conformément aux lois et réglementations applicables en matière de protection des données. <br>

          Nous utilisons des protocoles de sécurité standard pour assurer la sécurité des transactions effectuées sur nos applications en ligne. Toutes les informations sensibles telles que les informations de paiement sont cryptées à l'aide de la technologie SSL (Secure Socket Layer) pour garantir leur confidentialité et leur intégrité lors de la transmission sur Internet. <br>
  Nous conservons vos données personnelles aussi longtemps que nécessaire pour atteindre les finalités pour lesquelles elles ont été collectées, et nous nous conformons à des pratiques de conservation sécurisées pour assurer leur protection pendant cette période.

        
      </p>

      <p>
        Des mesures de sécurité sont en place pour protéger les données des clients contre tout accès non autorisé : <br>
        - Cryptage des données : Les informations sensibles sont cryptées pour garantir la confidentialité. <br>
        - Protection des informations personnelles : Des contrôles d'accès stricts sont mis en place pour assurer la sécurité des données personnelles des clients. <br>
        Des mesures de sécurité robustes sont mises en place pour protéger les données des fournisseurs contre tout accès non autorisé : <br>
        - Protection contre les attaques informatiques : Des pare-feu et des outils de détection des intrusions sont en place pour prévenir les menaces en ligne. <br>
        - Confidentialité des informations professionnelles : Les données professionnelles sont traitées avec le plus grand soin pour garantir la confiance des fournisseurs dans notre plateforme
        Des mesures de sécurité robustes sont en place pour protéger les données du livreur contre tout accès non autorisé : <br>
        - Chiffrement des données : Toutes les informations sont cryptées, assurant ainsi la confidentialité des données sensibles.
        Informations de localisation si vous utilisez des services basés sur la localisation pour suivre votre livraison. <br>
        Nous collectons ces informations lorsque vous nous les fournissez volontairement ou lorsque vous utilisez notre service de livraison.
        Contrôles d'accès stricts : Seuls les membres autorisés de notre équipe ont accès aux données du livreur

      </p>
      <div>
        <p>
          <span class="bold">4. UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI ? </span>
        </p>
    
        <p>En bref : nous pouvons utiliser des cookies et d'autres technologies de suivi pour collecter et stocker vos informations. <br>

          Nous pouvons utiliser des cookies et des technologies de suivi similaires (comme des balises Web et des pixels) pour accéder ou stocker des informations. Des informations spécifiques sur la manière dont nous utilisons ces technologies et sur la manière dont vous pouvez refuser certains cookies sont présentées dans notre Avis relatif aux cookies.
          
        </p>
    
      </div>

      <div>
          <p>
            <span class="bold">
             5. VOS DROIT
            </span>
          </p>
          <p>Nous respectons vos droits en matière de confidentialité des données et nous nous engageons à les protéger. Cette section décrit vos droits en vertu des lois et réglementations applicables en matière de protection des données.
          </p>
          <p>- Le droit d'être informé. <br>
            - Le droit d'accès. <br>
            - Le droit de rectification. Le droit à l'effacement. <br>
            - Le droit de restreindre le traitement. <br>
            - Le droit à la portabilité des données. <br>
            - Le droit d'opposition. <br>
            Les droits relatifs à la prise de décision automatisée et au profilage. Si vous souhaitez exercer ce droit, veuillez nous contacter via les coordonnées ci-dessous. En outre, si vous êtes un résident européen, nous notons que nous traitons vos informations afin d'exécuter les contrats que nous pourrions avoir avec vous (par exemple, si vous passez une commande par le biais du site), ou autrement pour poursuivre nos intérêts commerciaux légitimes énumérés ci-dessus.
            </p>
      </div>

      <div>
        <p>
          <span class="bold">
            6. DIVULGATION LÉGALE

          </span>
        </p>
        <p>
          Nous divulguerons toute information que nous collectons, utilisons ou recevons si la loi l'exige ou l'autorise, par exemple pour nous conformer à une citation à comparaître ou à une procédure judiciaire similaire, et lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui, enquêter sur une fraude ou répondre à une demande du gouvernement.

        </p>
    </div>

    <div>
      <p>
        <span class="bold">
          7. SÉCURITÉ DE L’INFORMATION

        </span>
      </p>
      <p>Nous prenons des mesures de sécurité raisonnables pour protéger vos informations personnelles contre la perte, l'utilisation abusive et l'accès non autorisé. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.

      </p>
  </div>
  <div>
    <p>
      <span class="bold">
        8. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?

      </span>
    </p>
    <p>En bref : Nous conservons vos informations aussi longtemps que nécessaire pour remplir aux fins décrites dans le présent avis de confidentialité, sauf disposition contraire de la loi. <br>
      Nous ne conserverons vos informations personnelles que le temps nécessaire aux fins énoncées dans le présent avis de confidentialité, à moins qu'une période de conservation plus longue ne soit requise ou autorisée par la loi (telle que la fiscalité, la comptabilité ou d'autres exigences légales). <br>
      Lorsque nous n'avons aucun besoin professionnel légitime et continu de traiter vos informations personnelles, nous supprimerons ou anonymiser ces informations, ou, si cela n'est pas possible (par exemple, parce que vos informations personnelles ont été stockées dans des archives de sauvegarde), nous stockons en toute sécurité vos informations personnelles et les isolent de tout traitement ultérieur jusqu'à ce que leur suppression soit possible.
      
    </p>
</div>
<div>
  <p>
    <span class="bold">
      9. QUELS SONT VOS DROITS À LA VIE PRIVÉE ?
    </span>
  </p>
  <p>En bref : Vous pouvez consulter, modifier ou résilier votre compte à tout moment.

    Retrait de votre consentement : si nous comptons sur votre consentement pour traiter vos informations personnelles, qui peut être un consentement exprès et/ou implicite selon la loi applicable, vous avez le droit de retirer votre consentement à tout moment. Vous pouvez retirer votre consentement à tout moment en nous contactant en utilisant les coordonnées fournies. <br>
    Veuillez toutefois noter que cela n'affectera pas la licéité du traitement avant son retrait ni,lorsque la loi applicable le permet, cela affecte-t-il le traitement de vos informations personnelles effectué sur la base de motifs de traitement licites autres que le consentement.
    Information sur le compte <br>
    Si vous souhaitez à tout moment consulter ou modifier les informations de votre compte ou résilier votre compte, vous pouvez : <br>
    À votre demande de résiliation de votre compte, nous désactivons ou supprimerons votre compte et vos informations de nos bases de données actives. Cependant, nous pouvons conserver certaines informations dans nos fichiers pour prévenir la fraude, résoudre des problèmes, faciliter toute enquête, faire respecter nos conditions légales et/ou nous conformer aux exigences légales applicables.
    
  </p>
 
 
</div>
<div>
  <p>
    <span class="bold">
      11. Devons-nous mettre à jour cet avis ?
    </span>
  </p>
  <p>En bref : Oui, nous mettrons à jour cet avis si nécessaire pour rester en conformité avec les lois en vigueur. <br>
    Nous pouvons mettre à jour cet avis de confidentialité de temps à autre. La version mise à jour sera indiquée par une mise à jour et la version mise à jour entrera en vigueur dès qu’elle sera accessible. Si nous apportons des modifications importantes à cet avis de confidentialité, nous pouvons vous en informer soit en affichant bien en vue un avis de ces modifications, soit en vous envoyant directement une notification. Nous vous encourageons à consulter fréquemment cet avis de confidentialité pour être informé de la manière dont nous protégeons vos informations.
    
  
</div>

<div>
  <p>
    <span class="bold">
      10. AVIS & CONTACTS ?
    </span>
  </p>
  <p>Si vous avez des questions ou des commentaires, vous pouvez contactez-nous par courrier à :

  </p>
  
  
</div>



  </div>

</div>
