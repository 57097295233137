import { Component, OnInit } from '@angular/core';
import { SpinnersService } from './spinners.service';

@Component({
  selector: 'app-spinners',
  templateUrl: './spinners.component.html',
  styleUrls: ['./spinners.component.less']
})
export class SpinnersComponent implements OnInit {

  loading:boolean = true;

  constructor(private loaderServive:SpinnersService){
    this.loaderServive.isLoading.subscribe((v:any)=>{
      setTimeout(()=>{this.loading = v},1000)
    })
  }

  ngOnInit(): void {}

}