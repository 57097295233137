
<app-spinners></app-spinners>
<div class="aideCenter">
    <div class="sectionCard">
        <h3>Centre d'aide</h3>
        <h5>Salut, comment pouvons-nous vous aider?</h5>
    </div>

    <div class="tabs">
        <div class="card" (click)="changeActive('souscription')">
            <p>Création d'une boutique</p>
            <img src="/assets/images/commande.png" alt="">
        </div>
        <div class="card"  (click)="changeActive('boutique')">
            <p>Boutique agbofy en ligne</p>
            <img src="/assets/images/boutiqueLigne.png" alt="">
        </div>
        <div class="card" (click)="changeActive('paiement')">
            <p>Gestion des paiements</p>
            <img src="/assets/images/paiement.png" alt="">
        </div>
        <div class="card" (click)="changeActive('expedition')">
            <p>Expédition & livraison</p>
            <img src="/assets/images/livraison.png" alt="">
        </div>
        <div class="card" (click)="changeActive('commercialisation')">
            <p>Comercialisation & promotion</p>
            <img src="/assets/images/commerce.png" alt="">
        </div>
        <div class="card" (click)="changeActive('delete')">
            <p>Suppression compte utilisateur</p>
            <img src="/assets/images/commerce.png" alt="">
        </div>
    </div>

    <div class="forms">
        <!-- <form >
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="search"  name="" id="" placeholder="tapez des mots clés comme 'paiement'">
        </form> -->
          <!-- Actual search box -->
  <div class="form-group has-search">
    <span class="fa fa-search form-control-feedback"></span>
    <input type="text" class="form-control"  placeholder="tapez des mots clés comme 'paiement'">
  </div>
    </div>


  <ng-container  *ngIf="value === 'souscription'" >

    <div class="contentTabs">
        <h4>Créer votre propre boutique</h4>
       

        <h6>Étape 1: <span class="subTitle"> Créer un compte  </span> </h6>
        <img class="imgs" src="/assets/images/connexion.png" alt=""> <br>
        <h6><span class="subTitle"> Si vous avez déjà un compte connectez vous</span> </h6> <br>
        <img class="imgs" src="/assets/images/comptes.png" alt=""> <br>
       
       

        
        <ul>
            <li>Une fois connecté créer votre boutique. </li>
            <img class="imgs" src="/assets/images/createBoutique.png" alt=""> <br>

            <li>Et ensuite remplir le formulaire pour créer votre boutique</li>
            <img class="imgs" src="/assets/images/infoBoutique.png" alt="">
          
        </ul> <br>

        
        <ul>
            <li>Après ça connectez vous à votre boutique et commencer à utiliser les fonctionnalités qui s'offre à vous   </li>
           

            <img class="imgs" src="/assets/images/fonctionnality.png" alt="">
            
        </ul> <br>

    </div>
  </ng-container >
  <ng-container *ngIf="value === 'boutique'">

    <div class="contentTabs">
       

        <h4>Boutique en ligne</h4>
        <p>Votre boutique en ligne Agbofy est la maison en ligne de votre entreprise. Vous pouvez l'utiliser pour créer des contenus,et vendre vos produits. Vous devez disposer du forfait Basic Agbofy ou d'un forfait supérieur pour vendre avec une boutique en ligne. Si vous êtes nouveau sur Agbofy, découvrez comment commencer .</p> <br>

        <p>Votre boutique en ligne est flexible, vous pouvez donc développer votre entreprise avec les personnalisations suivantes </p>
        <ul>
            <li>créez des menus personnalisés et des filtres de magasin pour aider les clients à naviguer dans votre magasin</li>
            <li>ajouter un domaine personnalisé</li>
            <li>configurer des boutons de paiement dynamiques pour vos pages produits</li>
            <li>concevez votre boutique en ligne à l'aide de thèmes réactifs</li>
        </ul>

        <h6>Conseils : <br> <span class="subTitle">Si les fonctionnalités de la boutique en ligne ne répondent pas aux besoins de votre entreprise, vous pouvez utiliser des outils de développement pour créer une vitrine personnalisée .</span> </h6>
    </div>
  </ng-container>
  <ng-container *ngIf="value === 'paiement'">

    <div class="contentTabs">
       

        <h4>Votre compte</h4>
        <p>Abgofy propose les types de comptes suivants :.</p> <br>

        <ul>
            <li>propriétaires de magasins</li>
            <li>personnel</li>
            <li>utilisateurs de l'organisation</li>
        </ul>

        <p>Pour en savoir plus sur le personnel et les autorisations, reportez-vous à Personnel .</p>
        <p>Pour désactiver votre boutique, reportez-vous à Mettre en pause ou désactiver votre boutique . Si vous souhaitez désactiver votre boutique pendant votre essai gratuit et que vous n'avez pas sélectionné de plan tarifaire, vous n'avez rien à faire. Votre boutique d'essai se bloquera à la fin de la période d'essai et vous ne serez pas facturé.</p>

        <h6>Conseils : <br> <span class="subTitle">Si les fonctionnalités de la boutique en ligne ne répondent pas aux besoins de votre entreprise, vous pouvez utiliser des outils de développement pour créer une vitrine personnalisée .</span> </h6>
    </div>
  </ng-container>
  <ng-container *ngIf="value === 'expedition'">

    <div class="contentTabs">
       

        <h4>Expédition et livraison</h4>
        <p>Avant de passer votre première commande, vous devez décider quelles méthodes d'expédition vous souhaitez utiliser , puis configurer les modalités d'expédition de votre magasin afin que vos clients puissent choisir une méthode de livraison au moment du paiement. Les méthodes de livraison que vous choisissez dépendent de ce qui est applicable à votre entreprise et du type de biens physiques que vous vendez. Les trois méthodes principales sont l'expédition, la livraison locale et le retrait local. Votre entreprise peut utiliser n'importe laquelle de ces méthodes pour livrer vos produits. <br>
        
        <p>Nous avons :</p>
        <ul>
            <li>Réseau de distribution Abgofy</li>
            <li>Paramétrage et gestion de vos expéditions et livraisons</li>
            <li>Comprendre l'expédition</li>
            <li>Expédition Agbofy</li>
            <li>Expédition neutre en carbone</li>
            <li>Utiliser les services de traitement des commandes et de dropshipping</li>
            <li>Promesse de magasin</li>
        </ul>
        <p>Assurez-vous de bien comprendre le processus de paiement . Lorsqu'un client passe à la caisse, il peut choisir de payer sa commande en utilisant l'une des méthodes que vous avez activées dans la zone Fournisseurs de paiement de votre interface administrateur Agbofy. Vous pouvez activer une variété de méthodes de paiement en utilisant Agbofy.</p>

        <h6>Conseils : <br> <span class="subTitle">Il y a différents éléments à prendre en compte lorsque vous choisissez les modes de paiement à proposer. </span> </h6>
    </div>
  </ng-container>
  <ng-container *ngIf="value === 'commercialisation'">

    <div class="contentTabs">
       

        <h4>Commercialisation et promotions</h4>
        <p>Il existe de nombreux outils pour vous aider à commercialiser votre boutique Abgofy. Vous pouvez consulter les indicateurs de performance clés du marketing, les principaux canaux marketing et créer des activités marketing et des automatisations directement à partir de la section Marketing de Abgofy. Si vous vendez vos produits dans une boutique en ligne, vous souhaiterez peut-être essayer d'améliorer le référencement pour aider les clients à trouver votre boutique en ligne dans les moteurs de recherche ou sur les réseaux sociaux.

            Vous pouvez également organiser des promotions telles que des ventes saisonnières et des ventes flash pour augmenter le trafic et les ventes dans votre magasin. <br>
        
        <p>Nous vous accompagnions dans :</p>
        <ul>
            <li>Élaboration d'un plan marketing</li>
            <li>Marketing de contenu</li>
            <li>Gérer les actifs de votre marque</li>
            <li>Améliorer l'optimisation des moteurs de recherche (SEO)</li>
            <li>Mise en place du marketing dans Agbofy</li>
            <li>Analyser le succès de vos campagnes de marketing en ligne</li>
            <li>Audiences Agbofy</li>
            <li>Expérience d'achat à distance</li>
            <li>Préparation des soldes saisonnières</li>
            <li>Collaborations Agbofy</li>
            <li>Ventes flash</li>
        </ul>
        

        <h6>Conseils : <br> <span class="subTitle">L'assistance Agbofy ne peut pas vous aider à configurer des services tiers, comme des comptes de réseaux sociaux ou de la publicité. Contactez plutôt le centre d'aide tiers pour le service que vous essayez d'utiliser. </span> </h6>
    </div>
  </ng-container>

  <ng-container  *ngIf="value === 'delete'" >

    <div class="contentTabs">
        <h4>Suppression compte utilisateur</h4>
        <p>2 posibilité de suppression :</p>

        <h6>Possibilité 1: <span class="subTitle"> La suppression définitive du compte</span> </h6>
        <h6>Possibilité 2:<span class="subTitle"> La réactivation du compte</span> </h6>
       

        <h5 class="title">Possibilité 1: <span>La suppression définitive du compte</span></h5>
        <ul>
            <li>Choix de l'application utilisé </li>
            <li>Email ou le numéro de téléphone</li> 
            <li>Le motif et la sugestion </li>
            <br>
            <img class="imgs" src="/assets/images/stepOne.png" alt=""> <br>

            <li>Un message qui vous signifie qu'exéder 90 jours votre compte sera définitivement supprimer </li>

            
            <img class="imgs" src="/assets/images/step2.png" alt=""> <br>
            <li> NB:Une fois la demande effectuée, votre compte sera desactivé pendant 90 jours. Une fois les 90 jours passés, la supresssion définitive du compte sera effective.</li> <br>

            <li>Une fois valider vous recevrez un message sur votre numéro</li> <br>
            <img class="imgs" src="/assets/images/step3.png" alt=""> <br>
          
        </ul> <br>
        

        <h5 class="title">Possibilité 2: <span>La réactivation du compte</span></h5>
        <ul>
            
            <img class="imgs" src="/assets/images/step4.png" alt=""> <br>

            <li>Choisissez l'application </li>
            <li>Entrer votre email ou le numéro de téléphone</li>

            <img class="imgs" src="/assets/images/step5.png" alt=""> <br>
            
            
            <li> Si la suppression n'a pas excédé 90 jours votre compte sera réactiver, <br> dans le cas contraire vous aller recevoir un message d'erreur.</li> <br>
          
        </ul> <br>
    </div>
  </ng-container >

    <div class="info">
        <h5>Parlez à un agent</h5>
        <div class="carte">
            <div class="chat">
                <img src="/assets/images/discuter.png" alt="">
                <div>
                    <h4>Chat en ligne </h4>
                <p> Nous sommes disponibles tous les jours de 8h à 20h pour toute assistance </p>
                </div>
            </div>
            <div class="phone">
                <img src="/assets/images/appel.png" alt="">
                <div>
                <h4>Appel </h4>
                <p> Lundi-Vendredi  08H-20H </p>
                <p> Samedi-Dimanche  08H-20H </p>
            </div>
        </div>
    </div>

</div>