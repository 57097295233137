import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.less']
})
export class AideComponent implements OnInit {

  value:string ='souscription';

  ngOnInit(): void {
    
  }

  changeActive(activeValue:string){
    this.value = activeValue;
  }

}
