<div class="container-footer">
  <div class="links">
    <div>
      <ul>
        <h5>Agbofy</h5>
        <li routerLink="/home">Presentation</li>
        <li (click)="goPrivacy()">Privacy</li>
        <li (click)="goCGU()">CGU</li>
       
      </ul>
    </div>
    <div>
      <ul>
        <h5 routerLink="/home">A propos</h5>
        <li routerLink="/tarification">Tarification</li>
        <li routerLink="/aide">Livraison</li>
        <li routerLink="/aide">Guide Livraison</li>
        <li routerLink="/aide">Guide vente</li>
        
      </ul>
    </div>
    <div>
      <ul>
        <h5>Aides</h5>
        <li routerLink="/aide">Boutique</li>
        <li routerLink="/aide">Paiement</li>
        <li routerLink="/aide">Commercialisation</li>
      </ul>
    </div>
    <div>
      <ul>
        <h5>Personnalisation</h5>
        
        <li routerLink="/aide">Aide</li>
        <li (click)="activeAccount()">Réactiver mon compte</li>
        <li  (click)="suppAccount()">Supprimer mon compte</li>
        <li routerLink="/aide">Nous contacter</li>
      </ul>
    </div>
   
  </div>
  <div class="bas-footer">
    <div class="social">
      <div class="icones">
        <a href=""><img src="/assets/images/Facebook.svg" alt="" /></a>
        <!-- <a href=""><img  class=""  src="/assets/images/instagram-fill.png" alt=""></a> -->
        <a href=""><img src="/assets/images/Twitter.png" alt="" /></a>
        <!-- <a href=""><img  class="reduce" src="/assets/images/youtube-fill.png" alt=""></a> -->
      </div>
    </div>
    <p class="text-footer">
      © Copyright 2023 Entreprise – Tout droits reservés.
    </p>
  </div>
</div>
