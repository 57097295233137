<app-spinners></app-spinners>

<div class="container-home">
    <div class="content">
        <div class="blockEcr">
            <h3>DÉBLOQUEZ VOTRE  COMMERCE</h3>
            <p>Vendez en ligne sur une plateforme de commerce électronique entièrement personnalisable, adaptée à la croissance de votre entreprise.</p>

            <a href="https://shop.agbofy.com/auth/register" target="_blank" > <button type="button" class="commencer pr-5 pb-3">Commencer <i class="fa-solid fa-arrow-right"></i></button></a>
        </div>

        <div class="img_desc">
            <img src="/assets/images/home-home.png" alt="">
        </div>

    </div>
    
    <div class="dev-activity">
       <div class="titre">
            <h1>DÉVELOPPEZ  VOTRE <br> ACTIVITÉ EN LIGNE</h1>
       </div>
       <div class="section">
            <div class="blockImg">
                <img src="/assets/images/contenu.webp" alt="">
            </div>
            <div class="contenu">
                <div class="carte">
                    <img src="/assets/images/webe.png" alt="">
                    <div>
                        <p class="title">Application personnalisée</p> 
                        <p class="description">Personnaliser une app sur Abgofy est un processus relativement simple, car Abgofy est une plateforme de commerce électronique conviviale qui ne nécessite pas de connaissances approfondies en programmation. Nous vous aidons à créer votre applicati</p>
                    </div>
                </div>
                <div class="carte2">
                    <img src="/assets/images/shop.png" alt="">
                    <div>
                        <p class="title">Vendre  sur votre boutique</p>
                        <p class="description">Nous mettons à votre disposition une large gamme  de choix c'est pourquoi nous mettons un point d'honneur à offrir une variété de produits pour satisfaire tous les goûts et besoins avec une livraison efficace et rapide </p>
                    </div>
                </div>
                <div class="carte">
                    <img src="/assets/images/boutiques.png" alt="">
                    <div>
                        <p class="title">Gérer  votre boutique en ligne</p> 
                        <p class="description">La gestion d'une boutique en ligne peut sembler être un défi, mais avec nous, vous pouvez optimiser votre succès de manière simple et efficace. A travers une plateforme intuitive , une personnalisation de la boutique , sécurité et fiabilité </p>
                    </div>
                </div>
                <div class="carte2">
                    <img src="/assets/images/activite.png" alt="">
                    <div>
                        <p class="title">Développer votre activité </p>
                        <p class="description">Le monde des affaires évolue rapidement, et pour rester en tête de la concurrence, il est essentiel de développer continuellement votre activité. Voici pourquoi choisir de développer votre entreprise avec nous peut être la clé de votre succès</p>
                    </div>
                </div>
            </div>
       </div>
    </div>
       <div class="section-indic">
            <div class="sectionEcriture"> 
                <h4>Commencez à construire votre <br> entreprise avec notre palteforme</h4>
                <p>
                    La première étape pour construire une entreprise solide est cruciale, et nous sommes là pour vous accompagner dans ce voyage passionnant. Voici pourquoi débuter avec notre plateforme peut être la fondation robuste dont votre entreprise a besoin</p>
            </div>
           <div class="text-center">
            <button style="text-align: right;" type="button" class="commencer ">Démarer </button>
           </div>
       </div>

        <div class="application">
            <div class="titre">
                <h1>Créer et personnaliser vos <br> applications</h1>
            </div>
            <div class="contents">
                <div class="images">
                    <img src="/assets/images/app-design.webp" alt="">
                </div>
                <div class="content-desc">
                    <h1>Adaptez vos applications <br> selon vos convictions</h1>
                    <p>Lorsqu'il s'agit d'utiliser des applications, la liberté de personnalisation est un atout précieux. Notre plateforme vous offre la possibilité unique d'adapter vos applications selon vos convictions et vos besoins spécifiques. Voici pourquoi cette flexibilité est essentielle. Si vous avez des préoccupations éthiques ou des convictions particulières, vous avez le pouvoir de personnaliser vos applications pour garantir qu'elles respectent vos normes éthiques personnelles. Nous croyons en la transparence et le respect de vos valeurs.</p>
                    
                 <button type="button" class="commencer ">Commencer </button>
                       
                </div>
            </div>
        </div> 

        <div class="join">
            <div class="titre">
                <h1>Pourquoi nous rejoindre ?</h1>
            </div>
            <div class="carde">
                <div class="carteOne">
                    <h3>Vendez facilement vos <br> articles</h3> <br>
                    <p>Vendez en ligne sur une plateforme de commerce <br> électronique entièrement </p>
                    <a href="" target="_blank" ><button type="button" class="joindre">Rejoindre</button></a>
                   <div> <img src="/assets/images/vente.svg" alt=""></div>
                </div>
                <div class="carteTwo">
                    <h3>Personnaliser vos  application</h3> <br>
                    <p>Envisager plusieurs approches en fonction de vos besoins spécifiques et des fonctionnalités que vous souhaitez offrir à vos utilisateurs. </p>
                    <a href="" target="_blank" ><button type="button" class="joindre mb-5">Rejoindre</button></a>
                   <div> <img src="/assets/images/build.png" alt=""></div>
                </div>
            </div>
       </div>  
       
       <!-- plateforme  -->

       <div class="plateforme">
            <div class="titre">
                <h1>La plateforme ecommerce qui <br> facilite  l'utilisation  pour vendre facilement vos <br> articles en afrique</h1>
            </div>
            <div class="chiffre">
                <div class="conteneur">
                   <div class="group">
                     <p class="titre-conteneur">3.9 </p>
                     <p class="mlion">M</p>
                   </div>
                    <p class="sub">Boutique en ligne</p>
                    <p class="place">Facile, rapide et sûr </p>
                </div>
                <div class="conteneur">
                    <div class="group">
                        <p class="titre-conteneur">80 </p>
                        <p class="mlion">%</p>
                      </div>
                    <p class="sub">Application mobile</p>
                    <p class="place">Mis en place avec facilité</p>
                </div>
                <div class="conteneur">
                    <div class="group">
                        <p class="titre-conteneur">73</p>
                        <p class="mlion">%</p>
                      </div>
                    <p class="sub">recommandation</p>
                    <p class="place">De la part de nos utilisateurs</p>
                </div>
            </div>
            <div class="text-center">
                <a href="https://shop.agbofy.com/auth/register" target="_blank"><button type="button" class="commencer">Commencer </button></a>
                
            </div>
       </div> 

       <!-- présentation des qualités -->

       <div class="quality">
        <div class="conteneurs ">
            <div class="content-description">
                <h1>Travailler avec des <br> experts</h1>
                <p>Quand il s'agit de réaliser vos projets et d'atteindre vos objectifs, travailler avec des experts est la clé du succès. Notre équipe d'experts chevronnés est prête à mettre à profit son savoir-faire pour vous accompagner vers l'excellence.</p>
              
                   <a href="https://shop.agbofy.com/auth/login" target="_blank"><button type="button" class="commencer">Engagez un expert</button></a> 
        
            </div>
            <div class="">
                <img class="images" style="float: right;" src="/assets/images/teams.webp" alt="">
            </div> 
        </div>

        <div class="conteneurs">
            <div >
                <img class="images" src="/assets/images/ameliore-vente.webp" alt="">
            </div> 
            <div class="content-description">
                <h1>Ameliorer vos ventes</h1>
                <p>L'amélioration des ventes est une aspiration partagée par de nombreuses entreprises. Que vous soyez une startup en pleine croissance ou une entreprise bien établie, augmenter vos ventes est essentiel pour assurer la prospérité. </p>
                
                <button type="button" routerLink="/tarification" class="commencer">Regarder nos prix</button>
                
            </div>
            
        </div>

        <div class="conteneurs mb-5">
            
            <div class="content-description">
                <h1>Méthodes de livraison <br> customisez </h1>
                <p>La personnalisation des méthodes de livraison est un moyen puissant d'améliorer l'expérience client et de gagner la fidélité de votre clientèle</p>
               
                   <a href="https://shop.agbofy.com/auth/register" target="_blank"><button type="button" class="commencer">Adhérer </button></a> 
               
            </div>
            <div >
                <img class="images " style="float: right;" src="/assets/images/shop.webp" alt="">
            </div> 
            
        </div>

        
       </div> 

       

   

</div>