<div class="header-container">
    <div class="submenu">
        <button type="button">Mise à jour</button>
        <p>30% de réduction pour les nouveaux clients avec un abonnement annuel.</p>
    </div>
     <nav>
        <a href="#" class="nav-icon" aria-label="homepage" aria-current="page">
            <img src="/assets/images/logo.png" alt="chat icon" />
           
          </a>
          <div class="main-navlinks">
            <button type="button" (click)="toggleNav()" class="hamburger" #hamburger  aria-label="Toggle Navigation" aria-expanded="false">
                <span></span>
                <span></span>
                <span></span>
              </button>
            <div class="navlinks-container" #navLinksContainer>
              <a href="#" aria-current="page">Accueil</a>
              <!-- <a href="#">Vendre</a>
              <a href="#">Gérer</a> -->
              <a  (click)="goToTarif()">Tarification</a>
              <a (click)="goAide()" >Aides</a>
              <a (click)="goContact()" >Contact</a>
            </div>
          </div>
          <div class="nav-authentication">
            <a href="#" class="user-toggler" aria-label="Sign in page">
              <img src="/assets/images/user.svg"/>
            </a>
            <div class="btns">
               <a target="_blank" href="https://shop.agbofy.com/"> <button type="button" class="conexion"><span>Connexion</span></button></a>
                <a target="_blank" href="https://shop.agbofy.com/auth/register"><button type="button" class="commencer"><span>Inscription</span></button></a>
            </div>
            
          </div>
        <!-- <div class="list-item">
            <div class="logo"><img src="/assets/images/Logo.webp" alt=""></div>
            <div class="menu">
                <ul>
                    <li><a href="">Démarer</a></li>
                    <li><a href="">Vendre</a></li>
                    <li><a href="">Gérer</a></li>
                    <li><a (click)="goToTarif()">Tarification</a></li>
                    <li><a href="">Ressources</a></li>
                </ul>
            </div>
            <div class="btns">
                <button type="button" class="conexion">Connexion</button>
                <button type="button" class="commencer">Commencer</button>
            </div>
        </div> -->
     </nav>
</div>
