<app-spinners></app-spinners>
<div class="removeAccount">
   
    <ng-container *ngIf="currentStep === 0">
        <div class="contentText">

            <div class="icons">
                <img src="/assets/images/loupe.png" alt="">
            </div>

        <h3>Recherche de  votre compte </h3>
        <form  class="form">
            <div class="groupForm">
            <label for="">Choix de l'application utilisé</label> <br>
            <select name="" id="">
                <!-- <option value="">Choisir le type de compte </option> -->
                <option value="">J'utilise l'application client</option>
                <option value="dog">J'utilise l'application forunisseur </option>
                <option value="cat">J'utilise l'application client</option>
            </select>
            </div>

            <div class="d-flex">
                <div class="groupForm">
                    <label for="">Email</label> <br>
                   <input type="email" placeholder="Entrer votre email">
                </div>
                <p>OU</p>
                <div class="groupForm">
                    <label for="">Numero de téléphone</label> <br>
                   <input type="number" placeholder="Entrer le numéro ">
                </div>
            </div>
            <div class="groupForm">
                <label for="">Entrer le motif</label> <br>
                <select name="" id="">
                    <!-- <option value="">Choisir le type de compte </option> -->
                    <option value="">Préocupation sur la confidentialité</option>
                    <option value="">Mauvaise expérience utilisateur</option>
                    <option value="">Je n'en ai plus besoins</option>
                    <option value="">Autres</option>
                </select>
                </div>
                <div class="groupForm">
                    <label for="">Suggestions</label> <br>
                 <textarea name="" id="" cols="10" rows="10"></textarea>
                </div>
            <button   (click)="onSubmit()" class="btns" type="submit">Suivant</button>
        </form>
         </div>
    </ng-container>

    <ng-container *ngIf="currentStep === 1">
       
        <div class="contentText">

            <div class="icons">
                <img src="/assets/images/alerte.png" alt="">
            </div>

            <h3>Demande de suppression de mon compte  agbofy </h3> <br>
            <p style="color: red;">Une fois la demande effectuée, votre compte sera desactivé pendant 90 jours.
                Une fois les 90 jours passés, la supresssion définitive du compte sera effective.</p> <br>
    
                <p>Vous pourrez annuler la demande a tout moment avant l'echeance de 90 jours</p> <br>
            <button (click)="onSubmit()" class="btns" type="submit">Suivant </button>
            <hr>
            <p>Si vous ne parvenez pas à supprimer le compte utilisateur agbofy , veuillez <span ><a class="contact" href="#">nous contactez</a></span></p>
        </div>
    </ng-container>

    <ng-container *ngIf="currentStep === 2">
        <div class="contentText">

                    <div class="icons">
                        <img src="/assets/images/password.png" alt="">
                    </div>

                     <h6>Veuillez entrer le numéro reçu par SMS</h6>
                <form  class="form">
                    <div class="groupForm">
                        <!-- <label for="">Numero de téléphone</label> <br> -->
                        <input type="number" placeholder="code OTP">
                    </div>
                    <button   (click)="onSubmit()" class="btns" type="submit">Suivant</button>
                </form>
         </div>
    </ng-container>

    <ng-container  *ngIf="currentStep === 3">
        <div class="contentText">

            <div class="icons">
                <img src="/assets/images/verifier.png" alt="">
            </div>

            <h3>Comptes supprimé avec succès  </h3> <br>
            
                <p>Votre demande de suppression de compte à été prise en compte. Vous recevrez un email ou un message de confirmation</p> <br>
           
            <hr>
            <p>Si vous voulez encore plus d'information  , veuillez <span ><a class="contact" href="#">nous contactez</a></span></p>
        </div>
    </ng-container>
</div>
