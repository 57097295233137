<app-spinners></app-spinners>

<div class="container-tarification">

    <div class="app-tarification">
        <h3>Choisissez le plan qui correspond le mieux à vos besoins <br> et commencez dès aujourd'hui </h3>
        <h5>nous sommes déterminés à offrir une expérience flexible et adaptée à vos besoins. <br> Explorez nos différents plans tarifaires et trouvez celui qui correspond le mieux à vos objectifs et à votre budget.</h5>
    </div>

   <div class="forfait">
        <div class="titre">
            <h1>Choisissez le forfait qui vous <br> convient le plus</h1>
         </div>
         <p>Nous sommes ravis de vous offrir une gamme de forfaits conçus pour répondre à vos besoins <br>spécifiques. Choisissez celui qui correspond le mieux à vos attentes et à vos objectifs. <br> Voici un aperçu de nos forfaits </p>

         <div class="btnsSwitch">
            <div class="cards">
                <!-- <button type="button" class="conexion" (click)="leftClick()">Mensuel</button> -->
                <button type="button" class="commencer" (click)="rightClick()">Mensuel</button>
            </div>
         </div>

         <div class="card-tarif">
            <div class="carteOne">
                <div class="title_carte">
                    <h3>Offre gratuite</h3>
                    <p>Accès à plusieurs fonctionnalités</p>
                    <p class="price">0 <span>F CFA</span></p>
                </div>
                <div class="section">
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text"> Gestion de plusieurs boutiques  </p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Gestion des commandes </p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Personnaliser l'application mobile</p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Gestion des zones de livraison</p>
                    </div>
                    <div class="content mb-3">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Compiler l'application mobile</p>
                    </div>
                    <div class="content mb-3">
                      <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                      <p class="text">Gestion des catégories</p>
                  </div>
                  <div class="content mb-3">
                    <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                    <p class="text">Gestion de plusieurs livreurs</p>
                </div>
                <div class="content mb-3">
                  <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                  <p class="text">Déployer un nouveau nom de domaine</p>
              </div>
                  <div class="content mb-3">
                    <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                    <p class="text">Gestion des horaires d'ouverture</p>
                </div>

                    <button type="button" class="commencer">Souscrire</button>
                </div>
                
            </div>
            <div class="carteTwo">
                <div class="title_carte mb-4">
                    <h3>Offre GOUHABO</h3>
                    <p>Des Fonctionnalités en plus </p>
                    <p class="price">15 000 <span>F CFA</span></p>
                    <!-- <div class="eco"><span>Economisez 20 000 /an</span></div> -->
                </div>
                <div class="section">
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Compiler l'application mobile </p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Gestion des horaires d'ouverture</p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Déployer un nouveau nom de domaine </p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Gestion des catégories</p>
                    </div>
                    <div class="content ">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">Gestion des catégories</p>
                    </div>
                    <div class="content ">
                      <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                      <p class="text">Gestion des commandes</p>
                  </div>
                  <div class="content ">
                    <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                    <p class="text">Gestion des zones de livraison</p>
                </div>
                <div class="content ">
                  <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                  <p class="text">Gestion de plusieurs boutiques</p>
              </div>
              <div class="content ">
                <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                <p class="text">Gestion de plusieurs livreurs</p>
            </div>
                <div class="content mb-5">
                  <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                  <p class="text">Personnaliser l'application mobile</p>
              </div>

                    <button type="button" class="commencer">Souscrire</button>
                </div>
                
            </div>
            <!-- <div class="carteOne">
                <div class="title_carte">
                    <h3>Business</h3>
                    <p>Lorem ipsum dolor <br> lorem ipsum</p>
                    <p class="price">200 000 <span>F CFA</span></p>
                </div>
                <div class="section">
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">lorem ipsum </p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">lorem ipsum </p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">lorem ipsum </p>
                    </div>
                    <div class="content">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">lorem ipsum </p>
                    </div>
                    <div class="content mb-3">
                        <p class="ronde"><i class="fa-solid fa-check icone"></i> </p>
                        <p class="text">lorem ipsum </p>
                    </div>

                    <button type="button" class="commencer-section">Inscription business</button>
                </div>
                
            </div> -->
         </div>

    </div>

    <div class="fonctionnalite">
        <div class="titre">
            <h1>Comparer les  fonctionnalités des <br> forfaits ci dessous</h1>
         </div>

        <div class="tableau">
          <table class="table table-bordered border-secondary">
            <thead>
              <tr>
                <th scope="col">
                   <div class="choice">
                    <p class="choice_plan">Choisisszez votre plan</p>
                    
                   </div>
                    <p  class="desc">Choisissez le plan qui vous conviendra le mieux pour vos clients </p>
                </th>
                <!-- <th scope="col">
                    <p class="theme">Gratuit</p>
                    <button type="button" class="commencer-section mb-3">Choisir cette offre</button>
                </th> -->
                <th scope="col">
                    <p class="theme">GRATUITE</p>
                    <!-- <button type="button" class="commencer-section mb-3">Choisir cette offre</button> -->
                </th>
                <th scope="col">
                    <p class="theme">GOUHABO</p>
                    <!-- <button type="button" class="commencer-section mb-3">Choisir cette offre</button> -->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><p>Gestion de plusieurs boutiques</p></th>
                <!-- <td><p>20 Pages</p></td> -->
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Gestion des commandes</p></th>
                <!-- <td><p>5 Pages</p></td> -->
                 <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th  scope="row"><p>Gestion des catégories</p></th>
                <!-- <td></td> -->
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Gestion des horaires d'ouverture</p></th>
                <!-- <td ></td> -->
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Gestion des horaires d'ouverture</p></th>
                <!-- <td ></td> -->
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Personnaliser l'application mobile</p></th>
                <!-- <td></td> -->
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Compiler l'application mobile</p></th>
                <!-- <td ></td> -->
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Assistance par e-mail</p></th>
                <!-- <td ></td> -->
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Assistance client 24h/24 et 7j/7</p></th>
                <!-- <td ></td> -->
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Analyses et rapports</p></th>
                <!-- <td ></td> -->
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row"><p>Gestion de compte</p></th>
                <!-- <td><img src="/assets/images/Vector.svg" alt=""></td> -->
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
             
            </tbody>
         </table>
        </div>


    </div> 

    <!-- <div class="fonctionnalite">
        <div class="titre">
            <h1>Comparer les  fonctionnalités des <br> forfaits ci dessous</h1>
         </div>

         <table class="table table-bordered border-secondary">
            <thead>
              <tr>
                <th scope="col">
                   lorem
                </th>
                <th scope="col">
                    lorem
                </th>
                <th scope="col">
                   loremù
                </th>
                <th scope="col">
                    lorem
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Nombre d'utilisateurs</th>
                <td>20 Pages</td>
                <td>
                  600 Pages <br> <span>Pages complémentaires à la demande</span>
                   
                </td>
                <td>
                Illimité <br><span>Pages complémentaires à la demande</span>
                    
                </td>
              </tr>
              <tr>
                <th scope="row"><p>Utilisateurs par page</p></th>
                <td>5 Pages</td>
                <td>50 Pages</td>
                <td>
                    Illimité <br><span>Pages complémentaires à la demande</span>
                    
                </td>
              </tr>
              <tr>
                <th  scope="row">Comprendre des fonctionnalités essentielles pour commencer</th>
                <td ><img src="/assets/images/Vector.webp" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Des fonctionnalités plus avancées pour une productivité accrue</th>
                <td ></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Conception et développement</th>
                <td ></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Options personnalisables pour répondre à vos besoins spécifiques</th>
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Stockage sécurisé des données</th>
                <td ></td>
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Assistance par e-mail</th>
                <td ></td>
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Assistance client 24h/24 et 7j/7</th>
                <td ></td>
                <td></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Analyses et rapports</th>
                <td ></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
              <tr>
                <th scope="row">Gestion de compte</th>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
                <td><img src="/assets/images/Vector.svg" alt=""></td>
              </tr>
             
            </tbody>
          </table>


    </div>  -->

    <div class="section-indic">
        <div class="sectionEcriture"> 
            <h4>Profitez de ces offres pour rendre vos <br> activités professionnelles et lucratives.</h4>
            <p>Nous sommes ravis de vous présenter des offres exceptionnelles conçues pour <br> booster la rentabilité de vos activités professionnelles. Profitez de ces opportunités <br> pour optimiser vos performances commerciales et atteindre de nouveaux sommets.</p>
            <!-- <button type="button" class="commencer-section mb-3">Commencer</button> -->
        </div>
        <div><img src="/assets/images/homme.webp" alt=""></div>
       
    </div>  

</div>