import { Component } from '@angular/core';

@Component({
  selector: 'app-supp',
  templateUrl: './supp.component.html',
  styleUrls: ['./supp.component.less']
})
export class SuppComponent {

}
